import React, { useEffect, useState } from 'react'
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import swal from 'sweetalert';
import { TypeAnimation } from 'react-type-animation';
import jopay from '../music/jopay1.mp3';
import ReactPlayer from 'react-player';
import Navbar from './layout/Navbar';
import Landing from './Landing';


function FrontPage() {

    const [visible, setVisible] = useState(false)
    const [loading, setloading] = useState(true)
    const [isPlaying, setIsPlaying] = useState(false);
    const [passworddata, setpassword] = useState({
        password: "",
    });




    const handleinput = (e) => {
        e.persist();
        setpassword({ ...passworddata, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        setVisible(true)
    }, []);

    const SubmitCode = (e) => {
        e.preventDefault();

        const data = {
            password: passworddata.password,
        }

        if (data.password === "11182000") {
            setVisible(false)
            setloading(false)
            setIsPlaying(true)
            localStorage.setItem('auth_token', 'mahal kita');
            // audioPlayer.current && audioPlayer.current.play();
        }
        else {
            swal("Warning", "Error Pin", 'warning');
            document.getElementById('pin').reset();
        }

    }

    


    return (
        <>
            <Dialog header="Enter 8 Pin" position='top' draggable={false} visible={visible} onHide={() => setVisible(false)}
                style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <div className="container">
                    <form onSubmit={SubmitCode} id='pin'>
                        <div className="row">
                            <div className="col-lg-12">
                                <label htmlFor="" className="form-label">
                                    Enter Password
                                </label>
                                <InputText type='password' maxLength={8} keyfilter={'pint'} onChange={handleinput} name='password' className='w-100' />
                            </div>
                            <div className="mt-3">
                                <Button className='p-button-sm p-button-info w-100' label="Enter" />
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>

            <Landing />



            {
                loading ? "" :
                    <>
                        <Navbar />
                        <div className="container mt-3">
                            <div className="row">
                                <Card className='bg-transparent'>
                                    <h5 className="text-center">
                                    </h5>
                                    <div className="">
                                    </div>
                                </Card>    
                            </div>
                        </div>
                        <div className="container">
                            <ReactPlayer
                                url={jopay}
                                playing={isPlaying}
                                width="300px"
                                height="50px"
                                loop={true}
                                volume={1}
                            />
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                    <Card className='bg-transparent'>
                                        <TypeAnimation
                                            sequence={[
                                                `Dear Sherilyn,
                                                
                                            \nMerry Christmas mahal, Nag papasalamat ako kay god kasi natagpuan ko na yung babaeng gusto ko na mamahalin ko habang buhay. Sa Dinami daming tao dito sa mundo 'hindi ba nakapagtataka kung bakit tayo pinagtagpo? tyka akalain mo same din tayo ng birthday para bang ikaw na yung taong binigay ni god sa para sakin.' Nong natagpuan kita at nong nakilala kita ay don din nawala yung sarili ko  sa iyo. Tila ba iba ka sa lahat ng mga babae. Mahal ikaw lang yung babaeng  pinapangarap ko sa buong buhay ko now nandito kana, I won't hurt you and letting you go from me kasi hindi ko kayang mawala ka sa piling ko. I promise that my loyalty,respect and my unconditional love na pang matagalan hinding hindi ko to sasayangin ang pag kakataong ibibigay mo sakin na mahalin kita despite your trust issues. Ipapakita ko sa family mo kung gaano ako ka seryoso at intention ko na mahalin kita. Sobrang saya ko talaga na di ko maipaliwanag. Di ko rin talaga malilimutan yung moment na you lean on my shoulder para bang na feel ko yung init ng pagmamahal mo It was an unforgettable moment for me na nakaka inlove talaga. 

                                            \n Sa tuwing  kasama kita, napapagtanto ko kung gaano ka kahalaga sa buhay ko shee. Ang iyong mga ngiti ay tila araw na sumisiklab sa aking madilim na mundo na nagbibigay din ng kulay. Mahal dito lang lage ako kahit anong mangyari nasa tabi mo lang ako nagmamahal lagi sayo at handa kitang alagaan. Thank you for letting me in your life and trusting me na I can love you back the way you deserved. Ikaw lang yung babaeng mamahalin ko habang buhay at wala ng iba I love you very much sherilyn. 

                                            \n Alam mo sa totoo lang ang sayasaya ko nung nakilala kita, di ko kasi inexpect na mapapasaya mo ako ng sobra, to the point na ikaw na ang safe place ko. I just wanna say thank you for making me happy the middle of nowhere 💖. Pinag mamalaki kita shee kasi di matutumbasan yung nararamdaman ko sayo sa iba. Hindi ko man masabi ng diretso sayo pero, lagi mong tandaan na sobrang na appreciate ko yung pagmamahal mo, pagmamahal kahit kailanman diko naranasan sa iba 🥺.  

                                            \n \n Merry Christmas, Sherilyn, and your family. 🎄  
                                            
                                            \nYour Special Person,

                                            Georgie Recabo 💓💖`
                                            ]}


                                            wrapper="p"
                                            speed={50}
                                            style={{ whiteSpace: 'pre-line', fontSize: '10px', display: 'inline-block', color: 'white' }}
                                        />
                                    </Card>

                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default FrontPage
